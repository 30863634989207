#starmap {
  position: relative;
}
#starmap .circle {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 10px;
  right: 10px;
  border: 2px solid white;
  border-radius: 100%;
}
@media (min-width: 568px) {
  #starmap .circle {
    top: 3px;
    bottom: 3px;
    left: 11px;
    right: 11px;
  }
}
@media (min-width: 768px) {
  #starmap .circle {
    border-width: 2px;
    right: 10px;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  #starmap .circle {
    top: 4px;
    bottom: 4px;
    left: 12px;
    right: 12px;
    border-width: 3px;
  }
}
#celestial-form {
  display: none;
}
