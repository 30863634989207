html {
  padding: 0;
  font-family: 'Montserrat', sans-serif; }

body {
  margin: 0; }

.btn {
  border-radius: 8px;
  height: 45px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: 0; }

.btn-primary {
  font-family: Helvetica;
  font-size: 14px;
  color: white;
  font-weight: bold;
  letter-spacing: 0;
  background-image: -webkit-linear-gradient(136deg, #3bb59a 0%, #00254c 100%);
  background-image: -o-linear-gradient(136deg, #3bb59a 0%, #00254c 100%);
  background-image: linear-gradient(-46deg, #3bb59a 0%, #00254c 100%);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }

.portrait_only {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: black; }
  @media screen and (orientation: landscape) {
    .portrait_only {
      display: block !important; } }
  .portrait_only .text {
    color: white;
    font-size: 32px;
    text-align: center;
    vertical-align: middle;
    position: fixed;
    top: 50%;
    margin-top: -39px;
    width: 100%; }
