.steps-component {
  width: 100%;
  float: left;
  background-color: #EEEEEE;
  border-radius: 6px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
@media (min-width: 992px) {
  .steps-component {
    display: none;
  }
}
@media (max-width: 992px) {
  .steps-component.step_2 > .container {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
  }
  .steps-component.step_2 > .container .btn {
    border-radius: 0;
    margin-bottom: 0;
  }
  .steps-component.step_2 > .container .btn-primary {
    background-color: #8ce647;
    background-image: none;
  }
}
.steps-component.step_2 .nav_3 {
  display: none;
}
.steps-component > .container {
  padding: 10px;
}
.steps-component > .container .head {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.steps-component > .container .steps-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.steps-component > .container .steps-container .step {
  padding: 10px 6px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  width: 50%;
  height: 148px;
  text-align: center;
  cursor: pointer;
}
.steps-component > .container .steps-container .step:first-of-type {
  margin-right: 12px;
}
.steps-component > .container .steps-container .step .number {
  opacity: 0.49;
  font-size: 64px;
  color: #171E40;
  font-weight: 700;
}
.steps-component > .container .steps-container .step .description {
  text-align: center;
  width: 60px;
  margin: auto;
  font-size: 14px;
  color: #171E40;
  font-style: italic;
}
.steps-component > .container button {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.steps-component > .container button .arrow-right {
  float: right;
  height: 19px;
  width: 20px;
  margin-right: 5px;
  background-image: url('../images/arrow-right.png');
}
.steps-component > .container button .arrow-left {
  float: left;
  height: 19px;
  width: 20px;
  margin-right: 5px;
  background-image: url('../images/arrow-left.png');
}
.steps-component > .container button.btn-white {
  background: white;
}
