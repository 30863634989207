.description-component {
  width: 100%;
  background-color: #EEEEEE;
  border-radius: 6px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
.description-component > .container {
  padding: 10px;
}
.description-component > .container > .head {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.description-component > .container > .head .toggle {
  height: 18px;
  width: 14px;
  float: right;
  background-image: url("../images/triangle.png");
  background-position: center;
  background-repeat: no-repeat;
}
.description-component > .container > .head.open .toggle {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.description-component > .container > .head.open + .container {
  display: block;
}
.description-component > .container > .container {
  display: none;
  margin-top: 8px;
  padding: 10px 6px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
.description-component > .container > .container textarea {
  border: 0;
  outline: 0;
  height: 110px;
  width: 100%;
  font-size: 14px;
}
.description-component > .container > .container .add-emoji-bar span {
  margin-right: 16px;
  cursor: pointer;
  font-size: 18px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 6px 18px;
}
.description-component > .container .amount_lines {
  text-align: right;
  margin-top: 8px;
  font-size: 12px;
}
.description-component > .container .amount_lines.error {
  color: red;
}
