#poster {
  margin: auto;
  background-color: #EEEEEE;
  border-radius: 6px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  width: 300px;
  height: 424px;
}
@media (min-width: 568px) {
  #poster {
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 707px;
    width: 500px;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  #poster {
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 989.8px;
    width: 700px;
  }
}
#poster.step_3 .button-container {
  height: 4px;
  margin-bottom: 0;
}
#poster.step_3 .button-container .btn {
  display: none;
}
#poster > .container {
  margin: auto;
  margin-bottom: 16px;
  color: #FFF;
  height: 100%;
}
#poster > .container.black {
  background-color: #000000;
}
#poster > .container.purple {
  background-color: #171E40;
}
#poster > .container.blue {
  background-color: #003C57;
}
#poster > .container.green {
  background-color: #1f002f;
}
#poster > .container.pink {
  background-color: #2d2d2d;
}
#poster > .container.invert {
  background-color: #FFF;
  color: #000;
}
#poster > .container.invert.black .inner-border {
  border-color: #000;
  color: #000 !important;
}
#poster > .container.invert.purple .inner-border {
  border-color: #171E40;
  color: #171E40 !important;
}
#poster > .container.invert.blue .inner-border {
  border-color: #003C57;
  color: #003C57 !important;
}
#poster > .container.invert.green .inner-border {
  border-color: #1f002f;
  color: #1f002f !important;
}
#poster > .container.invert.pink .inner-border {
  border-color: #2d2d2d;
  color: #2d2d2d !important;
}
#poster > .container .inner-border {
  border: 2px solid white;
  position: absolute;
  padding: 3%;
  top: 7px;
  bottom: 7px;
  right: 7px;
  left: 7px;
}
@media (min-width: 768px) {
  #poster > .container .inner-border {
    border-width: 3px;
    padding: 5%;
    top: 16px;
    bottom: 16px;
    right: 16px;
    left: 16px;
  }
}
@media (min-width: 992px) {
  #poster > .container .inner-border {
    border-width: 4px;
  }
}
#poster > .container .inner-border #starmap {
  text-align: center;
  width: 100% !important;
}
#poster > .container .inner-border .earth-border {
  width: 100%;
  padding-top: 100%;
  border-radius: 100%;
  border: 2px solid white;
  margin: auto;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}
#poster > .container .inner-border .text-container {
  position: relative;
  width: 85%;
  height: 108px;
  text-align: center;
  white-space: pre-wrap;
  margin: auto;
  font-size: 11px;
  font-weight: 500;
}
@media (min-width: 568px) {
  #poster > .container .inner-border .text-container {
    font-size: 16px;
    height: 180px;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  #poster > .container .inner-border .text-container {
    height: 250px;
    font-size: 20px;
  }
}
#poster > .container .inner-border .text-container .description {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 1.3;
  word-wrap: break-word;
}
#poster > .container .inner-border .poster-details {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  height: 14px;
  font-size: 4px;
  font-weight: 500;
}
@media (min-width: 992px) {
  #poster > .container .inner-border .poster-details {
    font-size: 8px;
    height: 22px;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  #poster > .container .inner-border .poster-details {
    height: 30px;
    font-size: 10px;
  }
}
#poster > .container .inner-border .logo {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  height: 8px;
}
@media (min-width: 568px) {
  #poster > .container .inner-border .logo {
    height: 14px;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  #poster > .container .inner-border .logo {
    height: 20px;
  }
}
#poster > .container .inner-border .logo.hide {
  display: none;
}
#poster > .container .inner-border .logo .logo-component {
  height: 100%;
}
#poster > .container .inner-border .logo .logo-component .logo-container {
  height: 100%;
  background-position: center;
  text-align: center;
}
#poster > .container .inner-border .preview-banner {
  position: absolute;
  font-size: 77px;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0.2;
  left: -22%;
  top: 39%;
}
@media (min-width: 568px) {
  #poster > .container .inner-border .preview-banner {
    font-size: 130px;
    left: -19%;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  #poster > .container .inner-border .preview-banner {
    font-size: 186px;
    left: -19%;
  }
}
#poster .button-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 992px) {
  #poster .button-container .btn-primary {
    border-radius: 0;
    background-color: #8ce647;
    background-image: none;
  }
}
@media (min-width: 992px) {
  #poster .button-container {
    display: none;
  }
}
#poster .button-container .btn-primary .arrow-right {
  float: right;
  height: 19px;
  width: 20px;
  margin-right: 5px;
  background-image: url("../images/arrow-right.png");
}