#poster-format {
  width: 100%;
  background-color: #EEEEEE;
  border-radius: 6px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
#poster-format > .container {
  padding: 10px;
}
#poster-format > .container > .head {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
#poster-format > .container > .head .toggler {
  height: 18px;
  width: 14px;
  float: right;
  background-image: url("../images/triangle.png");
  background-position: center;
  background-repeat: no-repeat;
}
#poster-format > .container > .head.open .toggler {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
#poster-format > .container > .head.open + .container {
  display: block;
}
#poster-format > .container > .container {
  display: none;
  margin-top: 8px;
  padding: 0 12px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
#poster-format > .container > .container .radio-label {
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 30px;
  width: 100%;
  clear: both;
  border-bottom: 1px solid #979797;
}
#poster-format > .container > .container .radio-label:last-of-type {
  border-bottom: none;
}
#poster-format > .container > .container .radio-label input {
  float: left;
  margin-right: 6px;
  margin-top: 8px;
}
#poster-format > .container > .container .radio-label .format-description {
  display: none;
}
#poster-format > .container > .container .radio-label .format-description.selected {
  display: block;
  line-height: 1.3;
}
#poster-format > .container > .container .radio-label .green-label {
  text-transform: uppercase;
  background-color: #529a45;
  color: white;
  padding: 2px 12px;
  border-radius: 10px;
  display: inline-block;
  line-height: 15px;
}
