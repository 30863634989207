#theme-selector {
  width: 100%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #EEEEEE;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border-radius: 6px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
#theme-selector .container {
  padding: 14px 10px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#theme-selector .container .theme {
  height: 95px;
  width: 19%;
  margin-right: 2%;
  border-radius: 7px;
  cursor: pointer;
}
#theme-selector .container .theme:last-of-type {
  margin-right: 0;
}
#theme-selector .container .theme.black {
  background-color: #000000;
}
#theme-selector .container .theme.purple {
  background-color: #171E40;
}
#theme-selector .container .theme.blue {
  background-color: #003C57;
}
#theme-selector .container .theme.green {
  background-color: #1f002f;
}
#theme-selector .container .theme.pink {
  background-color: #2d2d2d;
}
